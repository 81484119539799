import store from "../store/index";

export const prepareSingleFileToUpload = async file => {
    const fileBase64 = await convertFileToBase64(file);
    const fileName = file.name;

    return { file: fileBase64, file_name: fileName };
};

export const convertFileToBase64 = file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

export const hashCodeModelo = (payload) => {
    var hash = 0;
    for (var i = 0; i < payload.length; i++) {
      var code = payload.charCodeAt(i);
      hash = ((hash<<5)-hash)+code;
      hash = hash & hash;
    }
    return hash;
}

export const checkPermission = (permission) => {
  if (typeof permission === "string") {
    return store.state.user.data.permissoes.includes(permission);
  } else {
    const permissions = []
    for (let i = 0; i < permission.length; i++) {
      if (store.state.user.data.permissoes.includes(permission[i])) {
        permissions.push(permission[i])
      }
    }
    return permissions.length;
  }
};

const crypto = require('crypto');

export const stringToHex = (str) => {
  if(!str) return "#000"
  const hash = crypto.createHash('md5').update(str).digest('hex');
  const hex = '#' + hash.slice(0, 6);  
  return hex;
}